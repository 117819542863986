import React from "react"

type Props = {
  title: string
}

const PageTitle = ({ title }: Props) => {
  return (
    <div className="w-full bg-indigo-800 px-3 py-4 overflow-hidden">
      <div className="container mx-auto">
        <h2 className="text-xl md:text-3xl text-white text-center md:text-left">
          {title}
        </h2>
      </div>
    </div>
  )
}

export default PageTitle
