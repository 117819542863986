import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ContentBox from "../components/Content-box"

const AandoeningenOfKlachten = () => {
  return (
    <Layout title="Aandoeningen of klachten">
      <PageTitle title="Aandoeningen of klachten" />
      <ContentBox>
        <h4>ORTHOPEDISCHE AANDOENINGEN</h4>
        <p>
          Alle klachten die te maken hebben met de spieren en gewrichten
          ontstaan door bijvoorbeeld werk, sport, reuma, huishouden, spontaan,
          hobby.
        </p>
        <p>Dit valt onder de reguliere fysiotherapie en manuele therapie.</p>
        <h4>GERIATRISCHE AANDOENINGEN</h4>
        <p>
          Mensen die ouder en minder beweeglijk worden, kunnen daardoor
          belemmeringen of klachten ervaren. Ze komen hiervoor doorgaans in
          aanraking met de fysiotherapeut voor ouderen, de
          geriatriefysiotherapeut. De geriatriefysiotherapeut specialiseert zich
          in de zorg voor kwetsbare ouderen. Hij helpt ook patiënten die
          bijvoorbeeld door beroerte, dementie, de ziekte van Parkinson of een
          gebroken heup verschijnselen vertonen van ouderdom. De patiënten doen
          vooral oefentherapie, gericht op het herwinnen van en omgaan met
          verlies van mobiliteit en zelfstandigheid.
        </p>
        <h5>Gezinsleden en mantelzorgers</h5>
        <p>
          De geriatriefysiotherapeut adviseert en begeleidt ook gezinsleden en
          eventuele mantelzorgers. Die informeert hij bijvoorbeeld over het
          ziektebeeld, de vooruitzichten, de behandeling, dagelijkse handelingen
          en hulpmiddelen.
        </p>
        <p>Dit is een specialisatie binnen onze praktijk.</p>
        <h4>PSYCHOSOMATISCHE AANDOENINGEN</h4>
        <p>
          Psychosomatische fysiotherapie is een vorm van fysiotherapie waarbij
          de focus niet alleen ligt op de lichamelijke oorzaak van pijn maar op
          alle factoren die van invloed zijn op pijn. Denk hierbij bijvoorbeeld
          aan stress, je sociale vangnet, je thuissituatie, vrees om te bewegen
          en daardoor onvoldoende actief (kunnen) zijn maar ook bijvoorbeeld je
          werksituatie.
        </p>
        <p>Dit is een specialisatie binnen onze praktijk.</p>
        <h4>CHRONISCHE PIJNKLACHTEN</h4>
        <p>
          Chronische pijn betekend dat er pijn is die langer aanwezig is dan je
          op basis van wat de “schade” is aan het lichaam zou kunnen verklaren.
          Je hebt pijn maar er lijkt geen duidelijke oorzaak voor. Chronische
          pijnklachten kunnen iemands leven in hoge mate beïnvloeden. Jouw
          gezin, jouw werk, jouw contact met vrienden, alles verandert. Helaas
          is chronische pijn vaak niet zichtbaar aan een persoon en wordt hij of
          zij hierdoor moeilijk begrepen.
        </p>
        <p>Dit is een verbijzondering binnen onze praktijk.</p>
        <h4>LONGAANDOENINGEN</h4>
        <p>
          Alles wat met de problemen rond de ademhaling te maken heeft. Er
          bestaan veel soorten longaandoeningen, maar allemaal hebben ze een
          behoorlijke invloed op de mensen en hun omgeving. Mensen met een
          longaandoening moeten daarom ook hun leven aanpassen waardoor ze er
          beter mee kunnen omgaan. Enkele longaandoeningen:
          <ul>
            <li>COPD</li>
            <li>Astma</li>
            <li>Herstel na COVID-19</li>
          </ul>
          COPD is een verbijzondering binnen onze praktijk. Wij zijn aangesloten
          bij het COPD netwerk / Chronisch zorgnet.
        </p>
        <h4>CLAUDICATIO KLACHTEN</h4>
        <p>
          Problemen met de doorbloeding van de benen waardoor de belastbaarheid
          afneemt.
        </p>
        <p>
          Dit is een verbijzondering binnen onze praktijk en wij zijn
          aangesloten bij het claudicationetwerk.
        </p>
        <h4>NEUROLOGISCHE AANDOENINGEN</h4>
        <p>Ziektebeelden van de hersenen of zenuwen. TIA, CVA, Spasticiteit.</p>
        <p>
          Dit is een verbijzondering en specialisatie binnen onze praktijk en
          wij zijn aangesloten bij het parkingson netwerk.
        </p>
        <h4>REUMATISCHE AANDOENINGEN</h4>
        <p>
          Ziektebeelden die te maken hebben met alle vormen van reuma en artrose
          (slijtage).
        </p>
        <p>Dit valt onder de reguliere fysiotherapie</p>
        <h4>SPORTBLESSURES</h4>
        <p>Blessures verkregen door sporten in al zijn vormen.</p>
        <p>Dit is een verbijzondering binnen onze praktijk.</p>
        <h4>SLAAPPROBLEMEN</h4>
        <p>
          Bijna 10% van de bevolking heeft slaapproblemen. Dit betekent dat de
          persoon 3 dagen of meer in de week moeite heeft met slapen, gedurende
          3 maanden of langer. Slaapproblemen, zoals bijvoorbeeld insomnie
          (slapeloosheid), kunnen het leven sterk beïnvloeden. Het kan zorgen
          voor extreme vermoeidheid en slaperigheid, verminderde aandacht en
          prestatievermogen, stemmingsklachten als somberheid, futloosheid,
          grotere kans op ongelukken, gedragsproblemen en afhankelijkheid van
          slaapmedicatie. Er kunnen veel verschillende oorzaken zijn voor
          slaapproblemen. Lichamelijk ongemak zoals pijn of veelvuldig piekeren
          zijn hier voorbeelden van.
        </p>
        <p>Dit is een verbijzondering binnen onze praktijk.</p>
      </ContentBox>
    </Layout>
  )
}

export default AandoeningenOfKlachten
