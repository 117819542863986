import React, { ReactNode } from "react"

type Props = {
  children: ReactNode
}

const ContentBox = ({ children }: Props) => {
  return (
    <div className="max-w-4xl mx-4 md:mx-auto md:my-12 bg-white md:shadow-sm">
      <div className="px-8 md:px-20 py-10 mt-5 prose lg:prose-lg max-w-full">
        {children}
      </div>
    </div>
  )
}

export default ContentBox
